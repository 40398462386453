/* ui/src/App.tsx */

import { Route, Routes } from "react-router-dom";
import StartGamePage from "./components/navigation/startGame/StartGamePage";
import Navigation from "./components/navigation/Navigation";
import GameStateProvider from "./components/GameStateProvider";
import WebSocketProvider from "./components/WebSocketProvider";
import { HostPromptPage } from "./components/navigation/startGame/host/prompt/HostPromptPage";
import { HostVotePage } from "./components/navigation/startGame/host/vote/HostVotePage";
import { HostResultsPage } from "./components/navigation/startGame/host/results/HostResultsPage";
import { HostLobbyPage } from "./components/navigation/startGame/host/lobby/HostLobbyPage";
import { ParticipantLobbyPage } from "./components/navigation/startGame/participant/lobby/ParticipantLobbyPage";
import { ParticipantPromptPage } from "./components/navigation/startGame/participant/prompt/ParticipantPromptPage";
import { ParticipantVotePage } from "./components/navigation/startGame/participant/vote/ParticipantVotePage";
import { ParticipantResultsPage } from "./components/navigation/startGame/participant/results/ParticipantResultsPage";
import { ThemeProvider } from "@mui/material";
import { theme } from "./Theme";
import { HostSubmissionsPage } from "./components/navigation/startGame/host/submissions/HostSubmissionsPage";
import { ParticipantSubmissionsPage } from "./components/navigation/startGame/participant/submissions/ParticipantSubmissionsPage";
import { GuardedRoutes } from "./components/auth/GuardedRoutes";
import CreditsPage from "./components/navigation/credits/CreditsPage";
import InfoPage from "./components/navigation/info/InfoPage";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Routes>
        <Route path="/error" element={<div>An error occured.</div>} />
        <Route element={<GuardedRoutes />}>
          <Route element={<GameStateProvider />}>
            <Route path="/" element={<Navigation />}>
              <Route index element={<StartGamePage />} />
              <Route path="host">
                <Route element={<WebSocketProvider />}>
                  <Route path="lobby" element={<HostLobbyPage />} />
                  <Route path="prompt" element={<HostPromptPage />} />
                  <Route path="vote" element={<HostVotePage />} />
                  <Route path="submissions" element={<HostSubmissionsPage />} />
                  <Route path="results" element={<HostResultsPage />} />
                </Route>
              </Route>
              <Route path="play/:code">
                <Route element={<WebSocketProvider />}>
                  <Route path="lobby" element={<ParticipantLobbyPage />} />
                  <Route path="prompt" element={<ParticipantPromptPage />} />
                  <Route path="vote" element={<ParticipantVotePage />} />
                  <Route
                    path="submissions"
                    element={<ParticipantSubmissionsPage />}
                  />
                  <Route path="results" element={<ParticipantResultsPage />} />
                </Route>
              </Route>
              <Route path="credits" element={<CreditsPage />} />
              <Route path="info" element={<InfoPage />} />
            </Route>
          </Route>
        </Route>
      </Routes>
    </ThemeProvider>
  );
}

export default App;
