import { Box, Button, Container, Typography } from "@mui/material";
import { useState } from "react";
import axios from "axios";

export const CreditsPage = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handlePurchaseCredits = async (amount: number) => {
    try {
      setLoading(true);
      setError(null);
      
      // Call your backend API to create a Stripe checkout session
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/payments/create-checkout-session`, {
        amount: amount
      });

      // Redirect to Stripe checkout
      window.location.href = response.data.url;
    } catch (err) {
      setError("Failed to initiate payment. Please try again later.");
      console.error("Payment error:", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="sm">
      <Box sx={{ mt: 4, textAlign: "center" }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Purchase Credits
        </Typography>
        
        <Typography variant="body1" sx={{ mb: 4 }}>
          Credits are used to generate AI images. Purchase more credits to continue creating!
        </Typography>

        {error && (
          <Typography color="error" sx={{ mb: 2 }}>
            {error}
          </Typography>
        )}

        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            disabled={loading}
            onClick={() => handlePurchaseCredits(10)}
          >
            Buy 10 Credits - $5
          </Button>

          <Button
            variant="contained"
            color="primary"
            size="large"
            disabled={loading}
            onClick={() => handlePurchaseCredits(25)}
          >
            Buy 25 Credits - $10
          </Button>

          <Button
            variant="contained"
            color="primary"
            size="large"
            disabled={loading}
            onClick={() => handlePurchaseCredits(60)}
          >
            Buy 60 Credits - $20
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default CreditsPage;
