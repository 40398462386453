import { Box, Container, Link, Typography } from "@mui/material";

export const InfoPage = () => {
  return (
    <Container maxWidth="sm">
      <Box sx={{ mt: 4, textAlign: "center" }}>
        <Typography variant="h3" component="h1" gutterBottom>
          Art-Escapades Info
        </Typography>
        
        <Typography variant="h4" component="h2" gutterBottom sx={{ mt: 4 }}>
          How to Play
        </Typography>

        <Typography variant="body1" paragraph>
          Welcome to Art-Escapades! This is a party game where players create and vote on AI-generated images.
        </Typography>

        <Typography variant="h5" component="h3" gutterBottom sx={{ mt: 4 }}>
          Game Rules
        </Typography>

        <Typography variant="body1" paragraph>
          1. One player hosts the game and shares the join code with others
        </Typography>

        <Typography variant="body1" paragraph>
          2. Each round, players receive a prompt to create an AI image
        </Typography>

        <Typography variant="body1" paragraph>
          3. After submissions, everyone votes on their favorite image
        </Typography>

        <Typography variant="body1" paragraph>
          4. Points are awarded based on votes received
        </Typography>

        <Typography variant="h4" component="h2" gutterBottom sx={{ mt: 4 }}>
          Credits
        </Typography>

        <Typography variant="body1" paragraph>
          Each AI image generation costs 1 credit. You can purchase more credits in the Credits page.
        </Typography>

        <Typography variant="h4" component="h2" gutterBottom sx={{ mt: 4 }}>
          Contact & Support
        </Typography>

        <Typography variant="body1" paragraph>
          For customer support, please email us at{" "}
          <Link href="mailto:support@art-escapades.com" color="primary">
            support@art-escapades.com
          </Link>
        </Typography>

        <Typography variant="h4" component="h2" gutterBottom sx={{ mt: 4 }}>
          Policies
        </Typography>

        <Typography variant="body1" paragraph>
          • Refund Policy: Credits are non-refundable once used. Unused credits can be refunded within 30 days of purchase.
        </Typography>

        <Typography variant="body1" paragraph>
          • Cancellation Policy: You can cancel your participation in a game at any time. Used credits will not be refunded.
        </Typography>

        <Typography variant="body1" paragraph>
          • Dispute Resolution: Any disputes will be handled through our support team. Please contact us via email for assistance.
        </Typography>

        <Typography variant="body1" paragraph>
          • Promotional Terms: Special offers and promotions may have specific terms and conditions that will be clearly stated at the time of the offer.
        </Typography>

        <Typography variant="body1" paragraph>
          • Legal Restrictions: This service is intended for entertainment purposes only. Users must comply with their local laws regarding AI-generated content.
        </Typography>
      </Box>
    </Container>
  );
};

export default InfoPage;
