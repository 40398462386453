import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useWebSocketSend } from "../../../hooks/useWebSocketSend";
import { EventType, Player } from "dall-escapades-common";
import { GameStateContext } from "../../GameStateProvider";
import { TextInput, Button } from "../../../lib";
import { Stack, Typography, Tooltip } from "@mui/material";
import { AuthCtx } from "../../AuthContextProvider";
import axios from "axios";
import { fetchAuthSession } from "aws-amplify/auth";
import { config } from "../../../config";

const StartGamePage = () => {
  const [code, setCode] = useState<string>("");
  const { setGameState } = useContext(GameStateContext);
  const { user } = useContext(AuthCtx);
  const navigate = useNavigate();
  const send = useWebSocketSend();
  const [error, setError] = useState<string | null>(null);

  const handleJoin = async () => {
    const player: Player = {
      name: user?.email ?? "unknown",
    };
    if (setGameState) setGameState(null);

    try {
      // check if code is valid
      const res = await axios.get(`${config.API_URL}/joinGame/${code}`);
      if (res.status === 200) {
        send({ type: EventType.JOIN, data: { code, player } });
        navigate(`play/${code}/lobby`);
      } else {
        setError("Game not found.");
      }
    } catch (error) {
      setError("Game not found.");
      console.error("Error joining game:", error);
    }
  };

  const handleHost = () => {
    if (setGameState) setGameState(null);
    send({ type: EventType.HOST });
    navigate("host/lobby");
  };

  const testAuth = async () => {
    const session = await fetchAuthSession();
    axios
      .get(`${config.API_URL}/resource`, {
        headers: {
          Authorization: `Bearer ${session.tokens?.accessToken}`,
        },
      })
      .then((res) => console.log(res));
  };

  const testHealth = () => {
    axios.get(`${config.API_URL}/health`).then((res) => console.log(res));
  };

  const testBuy = async () => {
    const session = await fetchAuthSession();
    axios
      .post(
        `${config.API_URL}/buy-credits`,
        { amount: 100 },
        {
          headers: {
            Authorization: `Bearer ${session.tokens?.accessToken}`,
          },
        }
      )
      .then((res) => console.log(res));
  };

  return (
    <Stack spacing={2} alignItems="center">
      <Typography variant="h3" align="center">
        Welcome to Dall-Escapades!
      </Typography>

      <Tooltip title={!user ? "Please log in to host a game" : ""}>
        <span>
          <Button onClick={handleHost} disabled={!user}>
            host!
          </Button>
        </span>
      </Tooltip>

      <TextInput
        id="join-text-field"
        label="Enter Game Code"
        value={code}
        buttonText="join!"
        onChange={(e) => setCode(e.target.value)}
        onClick={handleJoin}
        errorText={error}
      />

      <Button onClick={testAuth}>test auth</Button>
      <Button onClick={testHealth}>test health</Button>
      <Button onClick={testBuy}>test buy</Button>
    </Stack>
  );
};

export default StartGamePage;
